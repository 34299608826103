:root{
    --color-dark: #555555;
	--color-light: #999999;
	--color-very-light-gray:#EEEEEE;
	--color-input:#F5F3ED;
	--color-gold:#DFB12E;
	--color-light-gold:#FFF9E2;
	--color-dark-gold:#AE8717;
	--color-semi-gold:#f9da82;
	--color-gold-text-dark-theme:#ad944e;
	--color-semi-gold-dark-theme:#d1bc81;
	--color-red:#DD3646;
	--color-green:#81CD34;
	--color-green-light:#A8E868;
	--theme-very-dark-bg:#302f2c;
	--theme-dark-bg:#393939;
	--theme-dark-color:#afafaf;
	--theme-dark-border:#464646;
	--theme-dark-input:#505050;
	--theme-dark-light-color:#ddd;
}
::-webkit-scrollbar {
    width: 5px!important;
    height: 5px!important;
}
::-webkit-scrollbar-track {
    background: rgba(0,0,0,0.2)!important;
}
::-webkit-scrollbar-thumb {
    background: var(--color-semi-gold)!important;
}
html {
	overflow-y: scroll !important; 
	min-height: 100%;
    display: flex;
	scroll-behavior: smooth;
}
html,body {
	font-size:10px;
	color:var(--color-dark);
	background-color:#EFF1F2;
	font-family:'Barlow', sans-serif !important;
	font-weight:400;
	height: auto !important;
	min-width: 100% !important;
}
body{
	background-image: url("../Images/bg.webp");
	background-position: top right;
	background-repeat: repeat-y;
	background-color: #F6F5EE;
	background-attachment: fixed;
	flex: 1;
    display: flex;
}
body.ReactModal__Body--open{
	overflow:hidden;
}
:focus {
    outline: none;
}
p{
	font-size:1.6rem;
}
a,
a:hover{
	color:var(--color-gold);
	cursor:pointer;
}
hr{
	border-top:4px solid rgba(0,0,0,.1);
}
button:focus,
a:focus {
    outline:0px none;
}
iframe{
	border:0px none;
}
.whole-container{
	padding: 85px 40px 40px 290px;
	transition: 0.5s all;
}
ol.info{
	padding:0 0 0 12px;
	font-size:1.4rem;
}
.wholeContent{
	min-height: 100%;
    display: flex;
    width: 100%;
}
#root{
	width: 100%;
}
#login{
	min-height: 100%;
}
#login .logo{
	display: flex;
}
#login .logo img{
	margin:100px auto 58px auto;
	display: block;
}
#login .logo .version{
	color:#603813;
	text-align:center;
	font-size:1.2rem;
}
#login .card{
	background-color:#fff;
	width:500px;
	margin:0 auto;
}
#login .inner-panel{
	border:1px solid #eeeeee;
	padding:35px 30px;
}

#login .card a{
    font-size:1.4rem;
}
#login .copy-tag{
	position: absolute;
    font-size: 1.2rem;
    bottom: -30px;
    left: 0px;
    color: #bbbbbb;
    width:100%;
    text-align:center;
}
#login input{
	padding-left:0px;
	font-size: 1.4rem;
	line-height: 1.4rem;
}
#login .input-group-prepend img{
	width:20px;
}
#header{
	width:100%;
	position:fixed;
	top:0px;
	left:0px;
	z-index:11;
    background-image: url("../Images/header_bg.webp");
	background-position: right center;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}
#header ul{
	list-style-type:none;
}

#header ul li a,
#header ul li button{
	padding:10px 10px;
	display:block;
	line-height: 25px;
	font-size: 1.2rem;
	color:#321F06;
	font-weight:bold;
}
#header ul li.user a,
#header ul li.user button{
	padding:8px 10px;
}
#header ul li.theme button{
	padding:10px 5px 10px 10px;
}
#header ul li button{
	border:0px none;
	cursor:pointer;
	background-color: transparent;
}
#header ul li.user img{
	margin-left:5px;
}
#header ul li.logo{
	width: 250px;
    text-transform: uppercase;
    font-size: 1.6rem;
    display: flex;
    align-items: center;
	justify-content: center;
	padding:0px;
	text-align:center;
}
#header ul li.logo img{
	height:45px;
}
#header ul li.membership{
	padding:6px 10px;
	line-height:16px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size:1.4rem;
}
#header ul li.membership span{
	color:var(--color-gold);
}
#header ul li.mobile-membership{
	display:none;
}
#header ul li.mobile-membership ul{
	position:absolute;
	top:45px;
	left:0px;
	background-color: #fff;
	width:100%;
	padding:0px;
	margin:0px;
	border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}
#header ul li.mobile-membership ul li{
	display:inline-block;
}

#header ul li img{
	max-width:100%;
	height: 24px;
}
#header ul li.logo img{
	height:35px;
	margin-right:10px;
}
#header .glyphicons-door{
	font-size: 1.06rem;
}
#header .menu-icon{
	display:none;
}
#header .messages,
#header .notifications{
	position: relative;
}
#header .messages .badge,
#header .notifications .badge{
	font-size:1.2rem;
	border-radius:5px;
	min-width:25px;
	position:absolute;
	z-index: 1;
	bottom:5px;
	right:0;
	pointer-events: none;
}
#header .sideBar{
	position:fixed;
	top:0px;
	left:0px;
	bottom:0px;
	width:250px;
	background-color:#fff;
	z-index:2;
	transition:0.5s transform;
	display:flex;
	flex-direction:column;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
}
#header .sideBar ul li a,
#header .sideBar ul li button,
#header .sideBar ul li span{
	text-align:left;
	padding:10px 13px 10px 13px;
	transition:0.5s all;
	color:var(--color-dark);
	display:flex;
	align-items: center;
	position:relative;
	font-size:1.4rem;
	font-weight:400;
	width: 100%;
}
#header .sideBar ul li a img{
	width:30px;
	height:30px;
	margin-right:12px;
	display:block;
}
#header .sideBar ul li a img.animated{
	display:none;
}
#header .sideBar ul li a:hover img.animated,
#header .sideBar ul li a img.static{
	display:block;
}
#header .sideBar ul li a:hover img.static{
	display:none;
}
#header .sideBar ul li a span{
	line-height: 12px;
}
#header .sideBar.open{
	transform: translateX(0px);
}
#header .sideBar ul{
	margin:0 15px;
	padding:0px;
}
#header .sideBar ul li{
	border-right:0px none;
	border-left:0px none;
	border-bottom:1px solid var(--color-very-light-gray);
	position:relative;
}

#header .sideBar .logo{
	width:100px;
	display: block;
	margin:30px auto;
}


#header  .sideBar ul li ul{
	transition: all 0.5s;
	opacity:1;
	overflow:hidden;
	margin:0px;
	padding:0px;
}
#header  .sideBar ul li ul.hidden{
	opacity:0;
	height:0px;
}
#header  .sideBar ul li ul li{
	margin-left:40px;
}
#header  .sideBar ul li ul li.last{
	border-bottom:0px none;
}
#header .sideBar ul li ul li a,
#header  .sideBar ul li ul li{
	font-size:1.3rem;
	font-weight:normal;
	font-style: italic;
	position: relative;
	line-height: 2rem;
}
#header .sideBar ul li ul li{
	padding: 0px 0px 0px 5px; 
}
#header  .sideBar ul li ul li a{
	padding: 10px 40px 10px 0px; 
}
#header .sideBar ul li ul li.activated,
#header .sideBar ul li ul li.activated a{
	color:#999999;
}
#header .sideBar ul li ul li.activated a.active{
	color:var(--color-gold);
}

#header .sideBar ul li a.active,
#header .sideBar ul li ul li.activated a:hover{
	color:var(--color-gold);
	background: radial-gradient(50% 100% at 50% 100%, #FFF9E2 0%, rgba(255, 255, 255, 0) 100%);
}
#header .sideBar ul li a:hover{
	background: radial-gradient(50% 100% at 50% 100%, #FFF9E2 0%, rgba(255, 255, 255, 0) 100%);
	text-decoration: none;
}
#header .sideBar .btn{
	border-radius: 0;
	padding:2px !important;
	margin:0 20px 20px 20px;
}
#header .sideBar .btn div{
	margin:0px;
	font-size: 1.4rem;
	line-height: 1.8rem;
	padding:1rem !important;
}

#header .side-bar-overlay{
	position:fixed;
	z-index:1;
	top:0;
	left:0;
	right:0;
	bottom:0;
	display:none;
}
#header .sideBar .badge{
	position:absolute;
	top:0;
	right:0;
	border-radius:0 0 5px 5px;
	font-size:1.2rem;
	font-weight: 700;
}

#header .sideBar .online-status{
	position:absolute;
	top:50%;
	transform: translateY(-50%);
	right:1rem;
}
#header .sideBar .online-status.top{
	top:2.4rem;
	transform: translateY(0);
}

#header .sideBar .dot,
#room #room-header .dot{
	position:absolute;
	content:" ";
	width: 15px;
	height: 15px;
	background: linear-gradient(264.66deg, #FFE05C 0%, #FFEEB4 32.87%, #FFEEB4 50.8%, #E7C054 101.49%);
	border-radius: 50%;
}
#room #room-header  .dot.tl{
	left: 12px;
    top: 16px;
}
#room #room-header  .dot.tr{
	right: 12px;
    top: 16px;
}
#header .sideBar .dot.tl{
	left:22px;
	top:22px;
}
#header .sideBar .dot.tr{
	right:22px;
	top:22px;
}
#header .sideBar .dot.br{
	right:22px;
	bottom:22px;
}
#header .sideBar .dot.bl{
	left:22px;
	bottom:22px;
}

#header li.menuMobile{
    display:none;
}
#header .btn-primary{
	margin: 0.5rem 1rem 0.5rem 0.5rem;
    padding: 1rem !important;
    font-size: 1.2rem !important;
}
#header .notifications-list {
    position: absolute;
    top: 4.6rem;
    right: 0;
    width: 30rem;
    background-color: #fff;
    border-left: 1px solid var(--color-very-light-gray);
    border-bottom: 1px solid var(--color-very-light-gray);
    border-right: 1px solid var(--color-very-light-gray);
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.15);
	padding:0rem;
	border-radius: 0 0 1rem 1rem;
	z-index: 2;
}
#header .notifications-list-content{
	max-height: 259px;
    overflow: auto;
	margin: 0 1rem;
}
#header .notifications-list-footer{
	height:1.5rem;
	border-top:1px solid var(--color-very-light-gray);
	margin: 0 1rem;
}
#header .notifications-list ul{
    padding:0;
	margin:0;
}
#header .notifications-list li{
    padding:1.5rem 1rem;
	font-size: 1.4rem;
	border-bottom:1px solid var(--color-very-light-gray);
	cursor:pointer;
	display:flex;
	align-items: center;
	font-weight:bold;
}
#header .notifications-list li:hover{
    background: radial-gradient(50% 100% at 50% 100%, #FFF9E2 0%, rgba(255, 255, 255, 0) 100%);
}
#header .notifications-list li:last-child{
	border-bottom: 0px none;
}
#header .notifications-list li .title{
    flex:1;
}
#header .notifications-list li .circle{
    display:inline-block;
	width:1rem;
	height:1rem;
	background-color: var(--color-red);
	border-radius:1rem;
	margin-left:1rem;
}

#header .notification-list-overlay{
	position: fixed;
	inset:0;
	z-index: 1;
}
#header .app-version{
	text-align:center;
	color:#cccccc;
	font-size:1.2rem;
	padding:0.5rem;
}

#material{
	padding: 45px 0px 0px 250px;
}
#material .material-content{
	flex:1;
	padding: 40px 340px 40px 40px;
}
#material .material-content.wider{
	flex:1;
	padding: 40px 40px 40px 40px;
}
#material .material-content .card{
	width:100%;
	display:block;
}

#material .material-content iframe{
    margin:0px;
    width:100%;
	border:0px none;
	height: calc((100vw - 710px) * 9 / 16);
}

#material .no-materials-content{
	display:flex;
	justify-content: center;
	align-items: center;
	padding-top:100px;
}

#material .card.side{
	width:300px;
	position: fixed;
    top: 45px;
    right: 0px;
	bottom: 0px;
	border-radius: 0 !important;
    border-top: 0px;
    border-bottom: 0px;
	margin:0 !important;
}
#material .card.side .header{
	display:flex;
	border-bottom: 1px solid #ddd;
	padding:1.5rem 0;
	cursor:pointer;
	align-items: center;
}
#material .card.side .header:hover h3,
#material .card.side .header.selected h3{
	color:var(--color-gold);
}
#material .card.side .header img{
	height:14px;
	transform: rotate(90deg);
	transition: all 0.5s;
}
#material .card.side .header img.rotate{
	transform: rotate(0deg);
}
#material .card.side h3{
	text-transform: uppercase;
	font-size:1.6rem;
	padding:0;
	margin:0;
}
#material .card.side .header:first-child{
	padding-top:0;
}
#material .card.side ul{
	margin:0;
	padding:0;
	list-style-type: none;
}
#material .card.side ul li{
	padding: 6px 0 6px 15px;
	border-bottom:1px solid #ddd;
}
#material .card.side ul li a{
	font-size:1.4rem;
	line-height:14px;
	color:var(--color-dark);
}
#material .card.side ul li a:hover,
#material .card.side ul li.selected a{
	color:var(--color-gold);
}
#material .card.side ul li.selected,
#material .card.side .header.selected{
	background: radial-gradient(50% 100% at 50% 100%, #FFF9E2 0%, rgba(255, 255, 255, 0) 100%);
}

#material .mobile-lessons{
	background-color:#fff;
	padding: 15px 40px 13px 40px;
	text-align:center;	
	font-size:1.4rem;
	font-weight:bold;
	line-height: 14px;
	box-shadow: 0 0 15px rgba(0,0,0,0.05);
	display:none;
	position:relative;
	color: var(--color-gold);
}
#material .mobile-lessons img{
	position:absolute;
	top:12px;
	left:12px;
	width:20px;
	height:20px;
}
#material .mobile-lessons img.right{
	left:auto;
	right:12px;
}
#material h2{
	text-transform: uppercase;
	font-weight: 900;
	font-size: 4rem;
	line-height: 4.8rem;
	background: linear-gradient(270deg, #E5BC48 20.36%, #F4E191 77.6%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	margin: 0 0 3rem 0;
	text-align: center;
}

#material .side .material-section{
	height:0;
	overflow: hidden;
	transition: all 0.5s;
}

#vs-list{
	padding: 45px 0px 0px 250px;
	overflow: visible;
}
#vs-list.info{
	height:100%;
}
#vs-list .vs-content{
	flex:1;
	padding: 40px 340px 40px 40px;
	height:100%;
}
#vs-list .vs-content .whole-container{
	padding: 0px 0px 0px 0px;
}

#vs-list .vs-content.wider{
	flex:1;
	padding: 40px 40px 40px 40px;
}

#vs-list .vs-content iframe{
    margin:0px;
    width:100%;
	border:0px none;
	height: calc((100vw - 710px) * 9 / 16);
}

#vs-list .vs-content .card{
	width:100%;
	display:block;
	height: 100%;
}
#vs-list .vs-content .card.vs-desc{
	height: 100%;
}
#vs-list .vs-content.info{
	height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#vs-list .vs-content.info .tiny-content{
	width: 75%;
    text-align: center;
}



#vs-list .card.side{
	width:300px;
	position: fixed;
    top: 45px;
    right: 0px;
	bottom: 0px;
	border-radius: 0 !important;
    border-top: 0px;
    border-bottom: 0px;
	z-index:10;
	margin:0 !important;
}
#vs-list .card.side h3{
	text-transform: uppercase;
	font-size:1.3rem;
	border-bottom:1px solid #ddd;
	padding-bottom:10px;
	margin:15px 0 0 0;
}
#vs-list .card.side h3:first-child{
	margin:0 0 0 0;
}
#vs-list .card.side ul{
	margin:0;
	padding:0;
	list-style-type: none;
}
#vs-list .card.side ul li{
	padding: 6px 0;
	border-bottom:1px solid #ddd;
}
#vs-list .card.side ul li a{
	font-size:1.4rem;
	line-height:14px;
	color:#321F06;
}
#vs-list .card.side ul li a:hover,
#vs-list .card.side ul li a.selected{
	color:var(--color-gold);
}

#vs-list .mobile-lessons{
	background-color:#fff;
	padding: 15px 40px 13px 40px;
	text-align:center;	
	font-size:1.4rem;
	font-weight:bold;
	line-height: 14px;
	box-shadow: 0 0 15px rgba(0,0,0,0.05);
	display:none;
	position:relative;
}
#vs-list .mobile-lessons img{
	position:absolute;
	top:12px;
	left:12px;
	width:20px;
	height:20px;
}
#vs-list .mobile-lessons img.right{
	left:auto;
	right:12px;
}

#vs-list .search{
	padding:10px;
}
#vs-list .add-group{
	padding:10px;
}
#vs-list .groups{
	max-height:250px;
}
#vs-list .group-list{
	padding-top:10px;
	padding-bottom:0px;
}
#vs-list .group-list .btn{
	font-size: 1rem !important;
    padding: 0.5rem 1rem !important;
}
#vs-list .vs-list{
	padding: 0 10px;
}
#vs-list .vs-list .vs{
	display:flex;
	padding:10px;
	border-bottom:1px solid #ddd;
	cursor:pointer;
	color:var(--color-dark);
}
#vs-list .vs-list .vs.group-container{
	cursor:default;
}
#vs-list .vs-list .vs.group-container:not(.no-clicable) .main-name{
	cursor:pointer;
}
#vs-list .vs-list .vs .group-info{
	width:100%;
}
#vs-list .vs-list .vs .group{
	display:flex;
}
#vs-list .vs-list .vs .group .main-name{
	font-size:1.4rem;
	font-weight:bold;
	line-height:1.4rem;
	flex:1;
	display:flex;
	align-items: center;
}
#vs-list .vs-list .vs .group .options{
	width:70px;
	display: flex;
    align-items: center;
    justify-content: flex-end;
}
#vs-list .vs-list .vs .group .options .badge{
	font-size:1.2rem;
	border-radius:0.5rem;
}
#vs-list .vs-list .vs .group .options img{
	height:17px;
	cursor:pointer;
}

#vs-list .group-header{
	border-bottom:4px solid #ddd;
	margin-bottom:3rem;
	text-align: center;
	padding:0.5rem;
	display:flex;
}
#vs-list .group-header img{
	width:30px;
	height:30px;
	margin-right:auto;
	display:none;
}
#vs-list .group-header h3{
	font-size:2.5rem;
}
#vs-list .group-header a{
	font-weight: 600;
}

#vs-list .vs-list .vs.selected{
	background: radial-gradient(50% 100% at 50% 100%, #FFF9E2 0%, rgba(255, 255, 255, 0) 100%);
}
#vs-list .vs-list .vs:hover{
	text-decoration: none;
}
#vs-list .vs-list .vs.group-container:not(.no-clicable) .main-name:hover,
#vs-list .vs-list .vs:hover:not(.group-container) .main-name,
#vs-list .vs-list .vs.selected .main-name{
	color:var(--color-gold);
}
#vs-list .vs-list .vs .info{
	display:flex;
	justify-content: center;
	flex-direction: column;
	flex:1;
}
#vs-list .vs-list .vs .info p{
	margin:0;
	padding: 0 0 0 10px;
}
#vs-list .vs-list .vs .info p.main-name{
	font-weight:bold;
	font-size: 1.4rem;
	max-width: 210px;
	line-height: 1.2rem;
	margin-bottom:0px;
}
#vs-list .vs-list .vs .info p.second-name{
	font-size: 1.2rem;
	font-style: italic;
	line-height: 1.2rem;
	margin-top:3px;
}
#vs-list .vs-list .vs .img{
	height:50px;
	width:50px;
	border-radius: 25px;
	position:relative;
	box-shadow: 0 0 8px rgb(0 0 0 / 30%);
}
#vs-list .vs-list .vs .img .badge{
	position:absolute;
	bottom:0;
	right:0;
	z-index:1;
	font-size: 1.2rem;
    border-radius: 0.5rem;
}
#vs-list .vs-list .vs .img img{
	height:100%;
	width:100%;
	border-radius: 25px;
	object-fit: cover;
}

#vs-list #chat{
	display: flex;
    flex-direction: column;
    position: fixed;
    bottom: 40px;
    top: 170px;
    left: 290px;
    right: 340px;
	z-index: 1;

}
#vs-list #chat.is-group{
    top: 160px;
}


#vs-list  #chat .all-messages .ps__rail-y{
	opacity:1 !important;
}

#vs-list  #chat .all-messages .scrollbar-container{
	padding-right:15px;
}

#vs-list #chat .all-messages{
	flex:1;
	overflow: hidden;
	position: relative;
}
#vs-list #chat .all-messages .scrollarea{
	padding-right:1rem;
}
#vs-list #chat .all-messages .scrollarea .scrollbar-container.vertical{
	right:-10px !important;
}

#vs-list #chat .write-message{
	min-height: 50px;
	max-height: 150px;
	display:flex;
	margin-top: 15px;
	align-items: center;
}

#vs-list #chat .emoji-overlay{
	position:fixed;
	z-index:1;
	left:0px;
	top:0px;
	bottom:0px;
	right:0px;
	cursor: default;
}
#vs-list #chat .write-message{
	position:relative;
}
#vs-list #chat .write-message  .message{
	background-color: #fff;
    border-radius: 1rem;
	min-height: 50px;
	max-height: 150px;
	overflow: auto;
	padding:5px;
	box-shadow: 0 0 15px rgb(0 0 0 / 10%);
	display:inline-block;
	width: 100%;
	white-space: pre-wrap;
	font-size: 1.4rem;
	flex:1;
}

#vs-list #chat .write-message .emotikons{
	position: relative;
    width: 50px;
	cursor:pointer;
	margin:0 1rem;
}
#vs-list #chat .write-message .add-images{
	position: relative;
    width: 50px;
	cursor:pointer;
	margin:0 1rem 0 0;
}
#vs-list #chat .write-message .add-images-container{

	background-color: #fff;
    border-radius: 1rem;
	box-shadow: 0 0 15px rgb(0 0 0 / 10%);
	height:50px;
	width:50px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor:pointer;
}
#vs-list #chat .write-message .add-images-container img{
	height:27px;
	position:absolute;
	top:12px;
	left:12px;
	z-index: 2;
	cursor:pointer;
	pointer-events: none;
}
#vs-list #chat .write-message .add-images-container input{
	height:100%;
	width:100%;
	border:0px none;
	background: transparent;
	opacity:0;
	cursor:pointer;
	font-size: 0;
}
#vs-list #chat .write-message .emotikons .emotikons-container{

	background-color: #fff;
    border-radius: 1rem;
	box-shadow: 0 0 15px rgb(0 0 0 / 10%);
	height:50px;
	width:50px;
	display: flex;
	align-items: center;
	justify-content: center;
}
#vs-list #chat .write-message .send{
    width: 50px;
	cursor:pointer;
}
#vs-list #chat .write-message .send .send-container{
    background-color: #fff;
	border-radius: 1rem;
	box-shadow: 0 0 15px rgb(0 0 0 / 10%);
	height:50px;
	width:50px;
	display: flex;
	align-items: center;
	justify-content: center;
	
}

#vs-list #chat .write-message .send svg{
	cursor:pointer;
	height:35px;
}
#vs-list #chat .write-message .send:hover svg{
	fill:#ffc107;
}

#vs-list #chat .write-message .emotikons:hover  svg{
	fill:#ffc107;
}

#vs-list #chat .write-message .placeholder{
	position: absolute;
    top: 0px;
    left: 0;
    z-index: 2;
    height: 50px;
    display: flex;
    padding: 0 1.5rem;
    align-items: center;
    font-size: 1.6rem;
}

#vs-list #chat .all-messages .current-message{
	display:flex;
}
#vs-list #chat .all-messages .current-message .image{
	width:50px;
	height:50px;
	margin-right: 10px;
	border-radius:25px;
	background-color:#fff;
	border: 2px solid #fff;
}
#vs-list #chat .all-messages .current-message .image.right{
	width:50px;
	height:50px;
	margin-right: 0px;
	margin-left: 10px;
	border-radius:25px;
	background-color:#fff;
}
#vs-list #chat .all-messages .current-message .image img{
	width:100%;
	height:100%;
	object-fit: cover;
	border-radius:25px;
}
#vs-list #chat .all-messages .current-message .current-message-content *{
	font-size:1.6rem;
}
#vs-list #chat .all-messages .current-message .current-message-content p{
	word-break: break-word;
}
#vs-list #chat .all-messages .current-message .current-message-content p a{
	color:var(--color-dark-gold);
	text-decoration: underline;
}
#vs-list #chat .all-messages .current-message.me .current-message-content p a{
	color:#fff79b;
}
#vs-list #chat .all-messages .current-message-date.my{
	text-align: right;
	border-top:1px solid var(--color-semi-gold);
	color:var(--color-semi-gold);
}
#vs-list #chat .all-messages .current-message-date.no-border{
	border-top:10px none;
	margin-top:0px;
}
#vs-list #chat .all-messages .current-message-date{
	text-align: left;
	font-size:1.2rem !important;
	line-height: 1.2rem;
	border-top:1px solid #ddd;
	margin-top:5px;
	padding-top:5px;
	color:#bfbfbf;
	font-weight: 400;
}
#vs-list #chat .all-messages .current-message-container{
	padding:2px 2px 7px 2px;
	border-radius:1rem;
	box-shadow: 0 0 15px rgba(0,0,0,0.1);
	white-space: pre-wrap;
}
#vs-list #chat .all-messages .current-message-container.mw-75{
	max-width:75%;
}
#vs-list #chat .all-messages .current-message-container .current-message-content{
	padding:0 5px;
}
#vs-list #chat .all-messages .img-content img{
	max-width:100%;
	cursor:pointer;
	border-radius:1rem;
}
#vs-list #chat .all-messages .current-message-content p{
	margin:0 0 5px 0;
}
#vs-list #chat .all-messages .current-message-content p.with-margin{
	margin:5px 0;
}
#vs-list #chat .all-messages .current-message-container p.name{
	font-weight:bold;
	padding-left:5px;
	margin-bottom:3px;
}

#vs-list #chat .all-messages .current-message.vs .current-message-container{
	margin:0 auto 15px 0;
	background-color:#fff;
	font-size:1.6rem;
}

#vs-list #chat .all-messages .current-message.me .current-message-container{
	margin:0 0 15px auto;
	background-color:#bda04f;
	color:#fff;
	font-size:1.6rem;
}

#vs-list #chat .all-messages .current-message .current-message-container.not-readed{
	border-left:4px solid #ffc107;
}

#vs-list #chat .chat-options-overlay
{
	position:fixed;
	left:0;
	top:0;
	right:0;
	bottom:0;
	z-index:100;
}
#vs-list #chat .chat-options
{
	position:absolute;
	left:0;
	top:0;
	z-index:101;
	height:auto !important;
}

#vs-list #chat .all-messages .current-message .message-options-container
{
	position:fixed;
	left:0;
	top:0;
	right:0;
	bottom:0;
	z-index:100;
}

#vs-list #chat .all-messages .current-message .message-options{
	position:fixed;
	width:200px;
	height:200px;
	background-color: #fff;
	box-shadow: 0 0 15px rgba(0,0,0,0.1);
	text-align: center;
	border-radius: 1rem;
	z-index:101;
}
#vs-list #chat .all-messages .current-message .message-options .card-body{
	padding:1.5rem;
}
#vs-list #chat .all-messages .current-message .message-options .option{
	padding:1rem 0;
	border-bottom: 1px solid var(--color-very-light-gray);
	cursor:pointer;
	font-size:1.4rem;
	text-transform: uppercase;
	line-height: 1.4rem;
	font-weight: 400;
	display: flex;
	align-items: center;
}
#vs-list #chat .all-messages .current-message .message-options .option .alert{
	margin:0;
	width:100%;
}
#vs-list #chat .all-messages .current-message .message-options .option.last{
	border-bottom: 0px none;
}
#vs-list #chat .all-messages .current-message .message-options .option:hover {
    background: radial-gradient(50% 100% at 50% 100%, #FFF9E2 0%, rgba(255, 255, 255, 0) 100%);
    text-decoration: none;
}
#vs-list #chat .all-messages .current-message .message-options .option img{
	height:24px;
	margin-right:1rem;
}
#vs-list #chat .all-messages .current-message .message-options .option:first-child{
	padding:0 0 1rem 0;
}

#vs-list #chat .all-messages .current-message .remove-message{
	font-style: italic;
	color:#cccccc;
}
#vs-list #chat .all-messages .current-message .remove-message.my{
	color: var(--color-semi-gold);
}

#vs-list #chat .add-images-modal{
	position:absolute;
	top:0;
	bottom:0;
	left:0px;
	right:0px;
	background-color: #424242;
	box-shadow: 0 0 15px rgb(0 0 0 / 15%);
	border-radius: 1rem;
	z-index: 100;
	display: flex;
	flex-direction: column;
}
#vs-list #chat .add-images-modal .image{
	flex:1;
	display:flex;
	align-items: center;
	justify-content: center;
}
#vs-list #chat .add-images-modal .image img{
	border-radius:1rem;
	box-shadow: 0 0 15px rgb(0 0 0 / 75%);
}

#vs-list #chat .add-images-modal .controls{
	min-height: 90px;
    padding: 0.5rem 2rem 2rem 2rem;
}

#vs-list #chat .add-images-modal .images-list{
	display: flex;
	height:130px;
	padding:2rem 0;
	margin: 0 2rem;
	overflow-x: auto;
	overflow-y: hidden;
	justify-content: center;

}
#vs-list #chat .add-images-modal .images-list .scrollarea-content{
	display: flex;
}

#vs-list #chat .add-images-modal .images-list .img{
	position:relative;
	height:100%;
}
#vs-list #chat .add-images-modal .images-list .slick-slider{
	padding:0 !important;
	background-color: transparent !important;
}
#vs-list #chat .add-images-modal .images-list img:not(.close){
	margin:0 0.5rem;
	border-radius:1rem;
	border:2px solid #fff;
	height:100%;
	cursor:pointer;
}
#vs-list #chat .add-images-modal .images-list .close-img{
	position: absolute;
    top: 0px;
    right: 6px;
    height: 32px;
    background-color: #fff;
    border-radius: 0.75rem;
    padding: 4px;
	display:none;
}
#vs-list #chat .add-images-modal .images-list .img:hover .close-img{
	display:block;
}
#vs-list #chat .add-images-modal .images-list .close-img img{
	opacity: 1;
    cursor: pointer;
    height: 25px;
}
#vs-list #chat .add-images-modal .images-list img.selected{
	border:2px solid var(--color-gold);
}
#vs-list #chat .add-images-modal .close-images{
	position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3000;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

#vs-list .vs-tabs .nav-link {
	box-shadow: 0 0 15px rgb(0 0 0 / 15%);
	-webkit-user-select: none; 
    -ms-user-select: none; 
    user-select: none; 
    -webkit-touch-callout: none;
	cursor:pointer;
	max-width: 150px;
    display: flex;
    line-height: 1.1rem;
	align-items: center;
	
}
#vs-list .vs-tabs .btn-item {
	margin-left:auto;	
}
#vs-list .vs-tabs .nav-tabs {
	align-items: center;
  
}
#vs-list .vs-tabs .nav-tabs img.back {
	display:none;
}
#vs-list .vs-tabs .nav-tabs li.name {
	margin-right:auto;
	font-size:1.6rem;
	font-weight:600;
	display: flex;
	align-items: center;
	display:none;
}
#vs-list .vs-tabs .nav-tabs li  img {
	height:24px;
	margin-right:0.7rem;
	pointer-events: none;
  
}
#vs-list .vs-tabs .nav-tabs li span {
	pointer-events: none;
  
}
#settings h2{
	font-weight: bold;
	margin:2rem 0;
	font-size:1.8rem;
	text-transform: uppercase;
}
#ls-gift h2{
	text-transform: uppercase;
	font-weight: 900;
	font-size: 6rem;
	line-height: 7.2rem;
	background: linear-gradient(270deg, #E5BC48 20.36%, #F4E191 77.6%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	margin: 0 0 3rem 0;
	display:none;
}

#ls-gift .col-12{
	padding:0 4rem;
}
#ls-gift .col-12:nth-child(2){
	border-left:1px solid #ddd;
}
#ls-gift .table{
	margin: 0;
}
#ls-gift .row{
	margin: 0 -4rem;
}
#ls-gift .tabs,
#my-vs .tabs{
	width:100%;
	display:none;
	list-style-type: none;
	padding:0;
	margin:0 0 3rem 0;
	position:relative;
}
#my-vs .tabs{
	display:flex;
	margin: 0 0 1.6rem 0;
}
#ls-gift .tabs:after,
#my-vs .tabs:after{
	content:" ";
	position:absolute;
	height:4px;
	width:100%;
	background-color: #fff;
	bottom:-4px;
	left:0;
	box-shadow: 0px 0px 15px rgb(0 0 0 / 15%) !important;
}
#ls-gift .tabs .tab-item,
#my-vs .tabs .tab-item{
	flex:1;
	text-align: center;
	background-color: #fff;
	border-radius:1rem 1rem 0 0;
	padding:1rem;
	margin:0 1rem;
	box-shadow: 0px 0px 15px rgb(0 0 0 / 15%) !important;
}
#my-vs .tabs .tab-item{
	flex:none;
	margin:0 0.5rem;
	padding:1rem 2rem;
	cursor:pointer;
	position:relative;
}
#ls-gift .tabs .tab-item.selected,
#my-vs .tabs .tab-item.selected{
	background: linear-gradient(180deg, #FFEEB4 14.06%, #E7C054 100%);
}
#ls-gift .tabs .tab-item.selected span,
#my-vs .tabs .tab-item.selected span{
	background: #fff;
	-webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
#ls-gift .tabs .tab-item span,
#my-vs .tabs .tab-item span{
	font-size:2.4rem;
	text-transform: uppercase;
	background: linear-gradient(270deg, #E5BC48 20.36%, #F4E191 77.6%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
	font-weight:900;
}
#my-vs .tabs .tab-item span{
	font-size:1.6rem;
}
#my-vs .tabs .tab-item div.badge{
	position:absolute;
	top:-5px;
	right:-5px;
	font-size:1.2rem;
	border-radius:0.5rem;
}
.ReactModalPortal .chat-receiver-list__menu.open .dropdown-menu{
	display:block;
	min-width:11.6rem;
}

#wc-footer > div, #wc-footer span{
	color: rgba(255, 255, 255, 0.5);
}
.daily-posts{
	position:relative;
}
.daily-posts .post{
	margin-bottom:15px;
}
.daily-posts .new{
	border-left: 4px solid var(--color-gold) !important;
}
.daily-posts .post .header{
	padding-bottom:10px;
	border-bottom:1px solid #ddd;
	display:flex;
}
.daily-posts .post .header *{
	color:#aaa;
	font-size:1.4rem;
}
.daily-posts .post .header img{
	height:20px;
	cursor:pointer;
	margin-left:20px;
}
.daily-posts .post .content{
	padding-top:20px;
}
.daily-posts .post .from{
	text-transform: uppercase;
	font-weight: bold;
	color:var(--color-gold);
	margin-left:10px;
}
.daily-posts .nav-tabs{
	margin-bottom:0px;
	border:0px;
}
.daily-posts .nav-tabs li{
	font-size:1.2rem;
}

.daily-posts .nav-tabs .nav-link {
    padding: 1.4rem 0.8rem;
	text-align: center;
	line-height: 1.4rem;
	border-radius: 1rem;
    box-shadow: 0 0 15px rgb(0 0 0 / 15%);
}
.daily-posts .mobile-tabs{
	background-color:#fff;
	padding: 15px 40px 13px 40px;
	text-align:center;	
	font-size:1.4rem;
	font-weight:bold;
	line-height: 14px;
	box-shadow: 0 0 15px rgba(0,0,0,0.05);
	display:none;
	position:relative;
	color: var(--color-gold);
	border-radius: 1rem;
    box-shadow: 0 0 15px rgb(0 0 0 / 15%);
}
.daily-posts .mobile-tabs img{
	position:absolute;
	top:12px;
	left:12px;
	width:20px;
	height:20px;
}
.daily-posts .mobile-tabs img.right{
	left:auto;
	right:12px;
}
.daily-posts .nav-tabs .nav-item,
.vs-tabs .nav-tabs .nav-item{
	position:relative;
}
.daily-posts .nav-tabs .nav-item .badge,
.vs-tabs .nav-tabs .nav-item .badge{
	position:absolute;
	top:-8px;
	right:0px;
	border-radius:5px;
	font-size: 1.2rem;
}
.daily-posts .card.small-margin{
	margin-bottom:1rem;
}
.daily-posts .filter-panel{
	position:absolute;
	top:56px;
	left:0;
	width:100%;
	background-color: #fff;
	padding:2rem;
	border-radius: 0 0 1rem 1rem;
	box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15) !important;
	z-index: 1000;
}
.daily-posts .filter-panel-overlay{
	position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
}
.daily-posts .filter-warning-panel{
	border-top:1px solid var(--color-red);
	border-bottom:1px solid var(--color-red);
	margin-bottom:1rem;
	padding:1rem;
	display:flex;
	align-items: center;
	color:var(--color-red);
}
.reservation-datepicker{
    position:relative;
}
.reservation-datepicker .pop-up-calendar{
    position:absolute;
    top:4rem;
    left:0;
    z-index: 1000;
}
.reservation-datepicker .pop-up-calendar-overlay{
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    z-index: 999;
}
.calendar-events .event{
	margin-bottom:15px;
}
.calendar-events .card-body{
	cursor:pointer;
	padding:0px 1rem 0 0 !important;
}

.calendar-events .card-body .date{
	margin-right:15px;
	background: linear-gradient(180deg, #FFEEB4 14.06%, #E7C054 100%);
	padding:10px;
	font-weight:bold;
	border-radius: 1rem 0 0 1rem;
	font-size: 1.8rem;
	line-height: 2rem;
}

.calendar-events .card-body .title{
	font-size:2rem;
	line-height: 2rem;
}
.calendar-events .card-body .date .year{
	font-size:1.4rem;
	letter-spacing: 2px;
	text-align: center;
}
.calendar-events .year-separator{
	position:relative;
	padding:1rem 0;
	margin:0 0 1.5rem 0;
}
.calendar-events .year-separator hr{
	border-top: 1px solid var(--color-semi-gold);
}
.calendar-events .year-separator .year{
	position: absolute;
	left:50%;
	top:50%;
	transform: translateX(-50%) translateY(-50%);
	font-size: 1.8rem;
	padding:1rem;
	line-height: 1.8rem;
	border-radius: 1rem;
	border: 1px solid var(--color-semi-gold);
    background: radial-gradient(50% 100% at 50% 100%, #FFF9E2 0%, rgba(255, 255, 255, 0) 100%);
    background-color: #fff;
	font-weight: 600;
}


.modal-body{
	padding: 2.5rem;
}
.tiny-content p{
	margin:20px 0;
	font-size:1.4rem;
}
.tiny-content h1,
.tiny-content h2,
.tiny-content h3,
.tiny-content h4{
    font-size:2.6rem;
    font-weight:700;
    text-align:left;
    margin:0px 0 20px 0;
    text-transform: initial;
}
.tiny-content h2{
    font-size:2.4rem;
}
.tiny-content h3{
    font-size:2.2rem;
}
.tiny-content h4{
    font-size:2rem;
}
.tiny-content strong,
.tiny-content span,
.tiny-content em,
.tiny-content i{
    color:inherit;
    font-size:inherit;
}
.tiny-content a{
    font-size:inherit;
    color:var(--color-gold);
}
.tiny-content ul,
.tiny-content ol{
	margin-left:0px;
	margin-bottom:20px;
	padding-left: 20px;
}
.tiny-content ul li,
.tiny-content ol li{
	margin-bottom:0px;
	font-size:1.4rem;
}
.tiny-content ul ul,
.tiny-content ul ol{
	margin-bottom:0px;
}
.tiny-content blockquote{
    
    border-left: 3px solid #ddd;
    padding-left:10px;
    margin:25px 0;
}
.tiny-content blockquote *{
    
    font-style: italic;
}
.tiny-content table th{   
    border-bottom:2px solid #ddd;
    padding:10px;
    text-align:left;
}
.tiny-content table td{   
    border-bottom:1px solid #ddd;
	padding:10px;
	font-size:1.4rem;
}

.tiny-content p:first-child{
	margin-top:0px;
}

.chat-item__chat-info-msg {
    font-family: 'Poppins', sans-serif !important;
}

.chat-item__chat-info {
    padding: 10px 10px !important;
}

.ReactVirtualized__Grid__innerScrollContainer{
	max-width:100% !important;
}

.ReactVirtualized__Grid__innerScrollContainer div{
	border:0px none !important;
}
.ReactVirtualized__Grid__innerScrollContainer div[role = presentation],
.ReactVirtualized__Grid__innerScrollContainer div[role = alert]{
	width:100% !important;
	margin:0 !important;
}

.ReactVirtualized__Grid.ReactVirtualized__List.chat-virtualized-list{
	width:100% !important;
}

#layout{
	min-height: 100%;
	display: flex;
    flex-direction: column;
	width: 100%;
}

#layout .room-login-name{
	background-color: #fff;
	max-width:245px;
	padding:2rem;
	text-align:center;
	font-size:1.6rem;
}
#layout .waiting-info{
    width: 300px;
	text-align: center;
}
#layout .waiting-info img{
	height:200px;
}
#notification{
    position:fixed;
    width:300px;
    bottom:20px;
	right:20px;	
	z-index:99999;
}
#notification .notify{
    margin-top:15px;
}
#notification .notify .alert{
    margin-bottom:0px;	
	border-radius:10px;
	font-weight:600;
	border:0px none;
}
#forum,
#room{
	padding: 45px 0px 0px 250px;
	flex:1;
}
#room #room-content{
	position:fixed;
	top:0px;
	left:0;
	right:0;
	bottom:0;
	z-index: 12;
	width: 100%;
	background-color:#111;
	display:flex;
	flex-direction: column;
}
#room #room-header{
	height:45px;
	width: 100%;;
}
#room #room-header .logo{
	display: flex;
	align-items: center;
	justify-content: center;
	height:45px;
	padding:0 1rem;
}
#room #room-header img{
	height:35px;
	margin-right: 10px;
}
#room #room-video{	
	width: 100%;
	flex:1;
}
#room #room-footer{
	height:45px;
	width:100%;
	display: flex;
	
}
#room #room-footer .side-bar{
	position:absolute;
	bottom:45px;
	left:0px;
	top:45px;
	z-index:15;
	background-color:#111;
	min-width:250px;
	border-bottom:1px solid #333;
	overflow:hidden;
	border-top:1px solid #373737;
}
#room #room-footer .scrollarea-content{
	overflow: initial;
}
#room #room-footer .side-bar-content{
	padding:0 1.5rem;
}
#room #room-footer .side-bar h2{
	padding:1rem 0px;
	font-size:1.6rem;
	text-transform:uppercase;
	color:var(--color-semi-gold);
	text-align:center;
	border-bottom:1px solid #373737;
	margin:0px;
	background: radial-gradient(50% 100% at 50% 100%, #5c563f 0%, rgba(255, 255, 255, 0) 100%);
}
#room #room-footer .side-bar h2.top-border{
	border-top:1px solid #373737;
}

#room #room-footer .side-bar ul{
	list-style-type:none;
	margin:0px;	
	padding:0;
	font-size:1.2rem;
}

#room #room-footer .side-bar li{
	color:#fff;
	border-bottom:1px solid #333;
	display:flex;
	justify-content: flex-start;
	align-items: center;
	position:relative;
}
#room #room-footer .side-bar li .status{
	width:0.6rem;
	height:0.6rem;
	background-color: var(--color-dark);
	margin-right:0.25rem;
	border-radius: 0.3rem;
}
#room #room-footer .side-bar li .status.online{
	background-color: var(--color-green);
}
#room #room-footer .side-bar li a:not(.btn),
#room #room-footer .side-bar li div.name{
	color:var(--color-light);
	cursor:pointer;
	padding:8px 5px;
	font-size:1.4rem;
	line-height: 1.4rem;
}
#room #room-footer .side-bar li div.name{
	cursor:default;
	flex: 1 1;
}
#room #room-footer .side-bar li a{
	flex:1;
}
#room #room-footer .side-bar li a:not(.btn):hover{
	color:var(--color-gold);
}
#room #room-footer .side-bar li a.approval{
	margin-right:5px;
	padding: 0.4rem 0.75rem !important;
	font-size:1.2rem !important;
}
#room #room-footer .side-bar li a.approval:active{
	color:var(--color-dark) !important;
}
#room #room-footer .side-bar li:last-child{
	border-bottom:0px none;
}
#room #room-footer .side-bar ul li img.options{
	cursor:pointer;
}
#room #room-footer .side-bar ul li img.icon{
	height:1.8rem;
}
#room #room-footer .side-bar li .options-panel{
	position:absolute;
	top:3rem;
	right:0;
	width:22rem;
	padding:1rem;
	background-color: var(--theme-dark-bg);
	z-index: 6;
	border-radius: 0 0 1rem 1rem;
}
#room #room-footer .side-bar li .options-panel.top{
	bottom:3rem;
	top:auto;
	border-radius: 1rem 1rem 0 0;
}
#room #room-footer .side-bar li .options-panel-overlay{
	position:fixed;
	inset:0;
	z-index: 5;
}
#room #room-footer .side-bar li .btn{
	font-size:1.2rem !important;
	padding:0.8rem !important;
}
#room #room-footer .side-bar li select{
	font-size:1.4rem !important;
	padding:0.2rem 0.5rem !important;
	height:30px;
}
#room #room-footer button:not(.btn){
	background-color: transparent;
    height: 45px;
    border: 0px none;
    padding: 5px 15px;
    display: flex;
    justify-content: center;
	align-items: center;
	border-right:1px solid #333;
	color:var(--color-light);
	font-size:1.2rem;
}
#room #room-footer button.reservations{
	width:180px;
	line-height: 1.2rem;
}
#room #room-footer button.users{
	width:250px;
	line-height: 1.2rem;
}
#room #room-footer button.users .green{
	color:#05e924;
	margin:0 5px;
}
#room #room-footer button.users .red{
	color:#ff1e00;
	margin:0 5px;
}

#room #room-footer button img{
	max-height:80%;
	margin-right:1rem;
}
#room #room-footer button.last{
	border-left:1px solid #333;
	border-right:0px none;
	color:#fff;
	line-height: 1.2rem;
}
#room #room-footer button.last img{
	margin:0;
}
#room #room-footer button:hover{
	background-color:#191919;
}
#room #room-footer button.red:hover{
	background-color:var(--color-red);
}

#no-access{
	width:800px;
}
#no-internet {
    padding: 0px 0px 0px 250px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
	z-index: 5;
}
#no-internet div{
	background: linear-gradient(180deg, #EA7782 0%, var(--color-red) 100%) !important;
	color:#fff;
	font-size:1.4rem;
	padding:1.3rem;
	font-weight:bold;
}
#no-membership{
	padding: 100px 15px 55px 265px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid #ddd;
}
#no-membership.in-page{
	padding: 35px 15px 0px 15px;
	display:flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
#no-membership.in-modal{
	padding: 15px 15px 0px 15px;
	display:flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
#no-membership button{
	padding:20px !important;
	font-size:1.6rem !important;
}

#questions{
	margin:0px auto;
	width:600px;
}
#questions .logo{
	margin: 100px auto 58px auto;
}


#questionnaire{
    overflow: hidden;
    padding:0px;
}
#questionnaire .questionnaire-container{
    overflow:hidden;
    transition: all 0.5s ease-in;
	border-bottom:1px solid #eee;
}
#questionnaire .questionnaire-content{
    display:flex;
    flex-direction: column;
    transition: all 0.5s ease-in;
    position:relative;
}

#questionnaire .questionnaire-content .introduction,
#questionnaire .questionnaire-content .steps{
    padding:5px 0px 30px 0px;
}

#questionnaire .questionnaire-content .introduction p{
    margin:1.5rem 0;
}
#questionnaire .questionnaire-content .introduction h3,
#questionnaire .questionnaire-content .introduction h2,
#questionnaire .questionnaire-content .introduction h1{
    font-size: 2.5rem;
}

#questionnaire .questionnaire-content .steps .steps-container{
    overflow:hidden;
    transition: all 0.5s ease-in;
}
#questionnaire .questionnaire-content .steps .steps-content{
    display:flex;
    flex-direction: column;
    transition: all 0.5s ease-in;
    position:relative;
}

#questionnaire .questionnaire-content .steps .step-progressbar{
    display:flex;
    padding-bottom:20px;
}
#questionnaire .questionnaire-content .steps .step-progress .line{
    flex:1;
    height:5px;
    background-color: #eee;
    margin:0 2px;
	position:relative;
}
#questionnaire .questionnaire-content .steps .step-progress .line .overline{
    position:absolute;
    left:0;
    top:0;
    height:5px;
    width:0px;
    transition: all 1s;
}
#questionnaire .questionnaire-content .steps .step-progress .line .overline.here{
    background-color: #ccc;
    width:100%;
    z-index:1;
}
#questionnaire .questionnaire-content .steps .step-progress .line .overline.done{
    background-color: rgb(131,213,65);
    width:100%;
    z-index:2;
}

#questionnaire .questionnaire-content .steps .step{
    padding:10px 0;
}

#questionnaire .step-number{
	font-size:1.2rem;
}
#questionnaire .step-result{
	padding:10px 0px;
	border-bottom:1px solid #ddd;
}
#questionnaire label.selected{
	color:var(--color-gold)
}
#questionnaire label.normal{
	font-weight:normal !important;
}

.cover-item{
	border: 1px solid #ddd;
    padding: 15px;
}

.cover-item .menu-item,
.main-photo-container .menu-item{
	padding: 10px;
    position: absolute;
    top: 0px;
    right: 15px;
    border: 1px solid #ddd;
	line-height: 10px;
	background-color:#ffffff;
}

.cover-item .edit-image,
.cover-item .remove-image,
.main-photo-container .edit-image{
	top: 34px;
	padding: 7px 5px;
	width: 35px;
	cursor:pointer;
}
.main-photo-container .edit-image{
	top: 0px;
}
.file-list-item{
	overflow:hidden
}
.file-list-item img{
	max-width:100%;
}
.file-list-item img.icon{
	height:50px;
}
.cover-select,
.image-select{
	position:relative;
}
.cover-select .value,
.image-select .value{
	background-color: #eeeeee;
    border: 0px none;
    color: #7d7d7d;
    box-shadow: none;
	font-size: 1.2rem;
	align-items: center;
	height: 46px;
	line-height:1.5;
	font-weight:400;
	width: 100%;
	cursor:pointer;
	display:flex;
	border-radius: .25rem;
	border:1px solid #eee;
}
.image-select .value{
	height:auto;
}
.cover-select .items,
.image-select .items{
	position:absolute;
	top:43px;
	left:0px;
	width:100%;
	z-index:100;
	height:200px;
	overflow-y: auto;
	background-color: #fff;
}
.image-select .items{
	top:-200px;
}

.cover-select .item,
.image-select .item{
	display:flex;
	height: 46px;
	border-bottom:1px solid #ddd;
	border-left:1px solid #ddd;
	border-right:1px solid #ddd;
	font-size:1.2rem;
	align-items: center;
	cursor:pointer;
	background-color:#fff;
	padding:0px !important;
}
.image-select .item{
	height: auto;
}
.cover-select img{
	height: 46px;
	margin-right:10px;
}
.image-select img{
	max-width: 100%;
	max-height: 92px;
}
.image-select .items img{
	height: auto;
	max-height:auto;
	width:100%;
}
.cover-select .item:hover,
.image-select .item:hover{
	background-color:#00aeef;
	color:#fff;
}

.profile-avatar {
    width: 400px;
    margin: 0 auto;
    z-index: 1;
    position: relative;
}
.profile-avatar.fluid{
    width: 90%;
}

.profile-avatar .sub-info{
	display: flex;
    font-size: 1.4rem;
    margin: 0px -50px 50px -50px;
    background-color: #ffffff;
    padding: 10px;
    z-index: 3;
    position: relative;
    border-top: 6px solid var(--color-semi-gold);
    box-shadow: 0 0 15px rgb(0 0 0 / 15%);
	border-radius: 1rem;
}
.profile-avatar.fluid .sub-info{
    margin: 0px -15px 25px -15px;
	text-align: center;
	display:block;
}
.profile-avatar.fluid .sub-info .star{
    text-align:left;
}
.profile-avatar .sub-info div{
	flex:0 0 50%;
}
.profile-avatar .sub-info .degree{
	text-align: right;
	display: flex;
    justify-content: center;
    flex-direction: column;
}
.profile-avatar .sub-info .degree.alone{
	flex:0 0 100%;
	text-align: center;
}
.profile-avatar .name-container{
	background-color: #fff;
	box-shadow: 0 0 15px rgb(0 0 0 / 15%) !important;
	padding:10px;
	z-index:2;
	position: relative;
	margin-top: -75px;
	display:flex;
	flex-direction: column;
	margin-bottom:0px;
	height:77px;
	align-items: center;
	justify-content: center;
	border-top-left-radius: 1rem;
	border-top-right-radius: 1rem;
}
.profile-avatar.fluid .name-container{
	margin-top: -50px;
}

.profile-avatar .name-container p{
    margin:0;
	text-align: center;
}
.profile-avatar .name-container p.main-name{
	font-weight: bold;
	font-size:1.7rem;
	line-height: 1.7rem;
}
.profile-avatar .name-container p.main-name a,
.profile-avatar-row .main-name a{
    color:inherit;
}
.profile-avatar.fluid .name-container p.main-name{
	font-size:2rem;
	line-height: 2rem;
}
.profile-avatar .name-container p.sub-name{
	font-size:1.4rem;
	line-height: 1.4rem;
	margin-top:5px;
}

.profile-avatar-row{
	display:flex;
	align-items: center;
}
.profile-avatar-row .main-name{
	display:flex;
	font-size:1.6rem;
	line-height: 1.6rem;
	font-weight: bold;
	margin:0;
}
.profile-avatar-row .info{
	flex:1;
}
.avatar-image{
	width:100%;
	position:relative;
	border-radius: 50%;
	box-shadow: 0 0 15px rgb(0 0 0 / 15%) !important;
	background-color: #fff;
}
.avatar-image.in-row{
	width:100px;
}
.avatar-image img{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100% !important;
	object-fit: cover;
	border-radius: 50%;
	border:10px solid #fff;
}
.avatar-image.in-row img{
	border:4px solid #fff;
}
.avatar-image:after {
	content: "";
	display: block;
	padding-bottom: 100%;
}

.avatar-image .star{
	position:absolute;
}

.header-avatar{
	height:30px;
	width:30px;
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid #ddd;
}
.header-avatar img{
	width:100% !important;
	height:100% !important;
	object-fit: cover;
	margin-left:0px !important;
}

.data-list{
	list-style-type: none;
	padding:0px;
	margin:0px;
}
.data-list-item-content{
	display:flex;
	border-bottom:1px solid #ddd;
	justify-content: center;
    align-items: center;
}
.data-list-item.header{
	border-bottom:4px solid #ddd;
}
.data-list-item.footer{
	border-top:4px solid #ddd;
	background-color:var(--color-very-light-gray);
}
.data-list-item.header div{
	font-weight:600;
	font-size:1.4rem;
}
.data-list-item-content div,
.data-list-item-content .item{
	padding:0.8rem;
	flex:1;
	font-size:1.4rem;
	margin:0px;
}
.data-list-item-content div div{
	padding:0px;
}
.data-list-item div.static,
.data-list-item-content div.static{
	width:120px;
	flex: none;
}
.data-list-item div.static-small,
.data-list-item-content div.static-small{
	width:65px;
	flex: none;
}
.data-list-item-content.categories div{
	flex:none;
	width:20%;
}
.data-list-item-content img.product{
	height:50px;
}

.data-list-item-content .double-comb-line{
	height:47px;
	align-items: center;
	display: flex;
}
.data-list-item-content .double-comb-line img{
	max-height:100%;
}
.data-list-item-content .double-comb-col{
	height:114px;
}
.data-list-item-content .cover-image{
	width:94px;
	height:94px;
	position:relative;
}
.data-list-item-content .cover-image .first{
	clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
	width:94px;
	height:94px;
	position:absolute;
	top:0px;
	left:0px;
	background-position: center center;
	background-size: cover;
}
.data-list-item-content .cover-image .second{
	clip-path: polygon(100% 0%, 100% 100%, 0% 100%);
	width:94px;
	height:94px;
	position:absolute;
	top:0px;
	left:0px;
	background-position: center center;
	background-size: cover;
}
.data-list-item-content.line-top{
	border-top:4px solid #ddd;
}
.products-list{
	border-top:2px solid #ddd;
	border-bottom:2px solid #ddd;
}

.main-photo-container{
	border:1px solid rgba(0,0,0,.125);
	padding:15px;
}
.main-photo-container img{
	max-width:100%;
}
.main-photo-container .one-image{
	position:relative;
}
.main-photo-container .one-image .menu-item{
	right:0px;
}

.card{
	background: #FFFFFF !important;;
	box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15) !important;;
	border-radius: 10px !important;;
	border:0px none !important;
	margin-bottom:4rem;
}
.card .card-header,
.modal-header{
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;
	color: var(--color-dark);
	padding: 0px 55px;
	border-bottom:1px solid var(--color-very-light-gray);
	background: linear-gradient(269.27deg, #FFF9E2 0%, #FFFFFF 50.56%);
	border-radius: 10px 10px 0px 0px;
	position:relative;
	height:56px;
	display: flex;
	align-items: center;
	justify-content: center;
}
.card .card-header:before,
.card .card-header:after,
.modal-header:before,
.modal-header:after{
	position:absolute;
	content:" ";
	width: 15px;
	height: 15px;
	top: 21px;
	background: linear-gradient(264.66deg, #FFE05C 0%, #FFEEB4 32.87%, #FFEEB4 50.8%, #E7C054 101.49%);
	border-radius: 50%;
}
.card .card-header:before,
.modal-header:before{
	left:22px;
}
.card .card-header:after,
.modal-header:after{
	right:22px;
}
.card .card-header select,
.card .card-header .form-control:focus,
.card .card-header .form-control:active{
	width:110px;
	border:1px solid #F4E9CF;
	background-color: #fff;;
}
.card .card-header.full-radius{
	border-radius:1rem;
}
.card .card-header .close{
	
	opacity:1 !important;
	cursor:pointer;
	margin: 2px 0 0 auto !important;
	padding:0 !important;
	height:24px;
	
}

.card .card-body{
	padding:22px;
}
.card .card-body .filter-data{
	border-bottom:2px solid #fff;
	position:relative;
	margin-bottom:2rem;
	display:flex;
	font-size: 1.4rem;
	flex-wrap: wrap;
}
.card .card-body .filter-data .item{
	margin-right:10px;
}
.card .card-body .filter-data:after{
	content: " ";
    position: absolute;
    height: 4px;
    width: 100%;
    background-color: #fff;
    bottom: -4px;
    left: 0;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%) !important;
}
.observer-container{
	display: flex;
	flex-wrap: wrap;
	border-radius:1rem;
	overflow: hidden;
}
.observer-container .tracks{
	position:relative;
	
}
.observer-container .tracks .user-info{
	background-color: rgba(0,0,0,0.7);
	color:#fff;
	position:absolute;
	z-index: 3;
	bottom:5px;
	left:5px;
	padding:0.2rem 0.5rem;
	font-size:1.2rem;
	border-radius:0.75rem;
	font-weight:bold;
	display: flex;
	align-items: center;
}

.observer-container .tracks .user-info .no-microphone{
	margin-right:0.25rem;
	height:1.5rem;
}
.observer-container .tracks .video-wraper{
	height:100%;
	width:100%;
	object-fit: cover;
	border-radius:1rem;
	padding:0.2rem;
}
.observer-container .tracks .video-wraper .no-video{
	height:100%;
	width:100%;
	background-color: #555;
	border-radius:1rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
.observer-container .tracks .video-wraper .no-video .avatar{
	height:50px;
	width:50px;
	background: linear-gradient(180deg, #FFEEB4 14.06%, #E7C054 100%);
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size:3rem;
	text-transform: capitalize;
	color:#000;
}
.observer-container .tracks video{
	height:calc(100% - 0.4rem);
	width:calc(100% - 0.4rem);
	object-fit: cover;
	border-radius:1rem;
	position:absolute;
	top:0.2rem;
	left:0.2rem;
	z-index: 2;
}
.observer-container .tracks video.hidden{
	visibility: hidden;
}
.row.m-40{
	margin-right: -40px;
    margin-left: -40px;
}
.service{
	display:flex;
	flex-direction: column;
	padding-right: 25px;
    padding-left: 25px;
}
.service .icon{
	width:240px;
	height:240px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
	margin:0 auto -50px auto;
	display:flex;
	align-items: center;
	justify-content: center;
	border:8px solid #fff;
}
.service .icon.photo{
	overflow: hidden;
}
.service .icon img{
	width: 55%;
    margin-bottom: 30px;
}
.service .icon.photo img{
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.service .icon img.individual{
    margin-bottom: 20px;
}
.service .card{
    flex:1;
	margin-bottom:6rem;
}
.slick-slider{
	padding:20px 0px;
	background-color:#fff;
	box-shadow:0 0 15px rgb(0 0 0 / 10%);
	border-radius: 0.25rem;
}
.slick-slider img{
	display:inline-block !important;
}

.slick-next, .slick-prev {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 25px;
    line-height: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%) rotate(45deg);
    transform: translateY(-50%) rotate(45deg);
    width: 25px;
    z-index: 2;
}

.slick-next {
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    right: 15px;
}

.slick-prev {
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
    left: 15px;
}

.slick-slider .slider-item{
	padding:0 5px;
}
.slick-slider .slick-disabled{
	opacity:0;
}

textarea.form-control,
input.form-control,
select.form-control,
select.form-control:focus,
select.form-control:active,
input.form-control:focus,
input.form-control:active,
.input-group-text,
.form-control:disabled, 
.form-control[readonly]{
	background-color:var(--color-input);
	border:0px none;
	color:var(--color-dark);
	box-shadow:none;
	font-size:1.4rem;
	padding:1.5rem 1.8rem;
	height:46px;
	border-radius: 10px;
}
select.form-control.thiner,
input.form-control.thiner{
	padding:1rem 1.5rem;
	height: auto;
}

textarea.form-control{
	height:100px;
}
.edit-icon,
.remove-icon{
	height:16px;
	cursor:pointer;
}
.book-icon{
	height:42px;
}
.move-icon{
	height:18px;
}
.list-icon{
	height:28px;
	cursor:pointer;
}
.info-icon{
	height:22px;
	cursor:pointer;
}
label{
	font-size: 1.4rem;
}
label.input-label{
	font-weight:bold;
}
.input-group-text{
	font-size:1.6rem;
}

.paid-off{
	border:1px solid #ddd;
	margin-bottom:15px;
	padding:20px;
}

.paid-off .price{

	color:#8dc63f;
	font-size:30px;
	font-weight:bold;
}
.paid-off .minimal-amount{
	font-size:1.2rem;
}
.clickable-panel {
    background-color: #fff;
    padding: 1rem;
    cursor: pointer;
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: bold;
    box-shadow: 0 0 15px rgba(0,0,0,0.15);
    border-radius: 1rem;
    min-height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
	border:1px solid #fff;
}
.clickable-panel.selected {
    border:1px solid var(--color-semi-gold);
	background: radial-gradient(50% 100% at 50% 100%, #FFF9E2 0%, rgba(255, 255, 255, 0) 100%);
}
.res-blocks{
	margin:2rem 0;
}
.res-blocks .block{
	height:5rem;
	border-top:1px solid var(--color-very-light-gray);
	border-right:1px solid var(--color-very-light-gray);
	border-left:1px solid var(--color-very-light-gray);
	position: relative;
	cursor:pointer;
}
.res-blocks .block.blocked{
	background-color: var(--color-red);
	border: 1px solid var(--color-red) !important;
	z-index: 1;
	cursor:default;
}
.res-blocks .block:not(.blocked):hover{
	border: 1px solid var(--color-green) !important;
	z-index: 1;
}
.res-blocks .block.selected{
	border: 1px solid var(--color-green) !important;
	z-index: 1;
	background-color: var(--color-green-light);
}
.res-blocks .block:last-child{
	border-bottom:1px solid var(--color-very-light-gray);
}
.res-blocks .block span{
	position:absolute;
	top:-0.8rem;
	left:50%;
	transform: translateX(-50%);
	font-size: 1.2rem;
	line-height: 1.2rem;
	padding:0 0.5rem;
	z-index: 2;
}
.res-blocks .block span.bottom{
	top:auto;
	bottom:-0.6rem;
}
.res-blocks.room .block{
	cursor:default;
	border:0px none;
	height:auto;
	min-height:2rem;
	padding:1rem 0;
}
.res-blocks.room .block span{
	color:#fff;
	top:-0.7rem;
}
.res-blocks.room .block span.bottom{
	top:auto;
}
.res-blocks.room .block ul li{
	
	border-top: 1px solid #2d2d2d !important;
	border-bottom:0px none !important;
	max-width:220px;
}
.res-blocks.room .block ul li:last-child{
	border-bottom: 1px solid #2d2d2d !important;
}
.res-blocks.room .block:hover{
	border: 0px none !important;
	z-index: initial;
}
.modal-content{
	border-radius: 1rem;
}
.modal-content.no-background{
	background-color:transparent;
	border:0px none;
}
.modal-content .back-button{
	border:0px none;
	background-color: transparent;
	color:#fff;
	font-size:14px;
}
.modal-content .back-button:hover{
	color:var(--color-gold);
}

.modal-header.white{
	background-color:#fff;
}
.modal-body label{
	font-weight:bold;
}
.modal-body .order{
	padding:5px 0 0 0;
}

.modal-body .payu-form .membership-item{
	border:1px solid #fff;
	height: 100%;
	padding:15px 15px 0px 15px;
	cursor:pointer;
	text-align: center;
	display: flex;
	flex-direction: column;
	box-shadow: 0 0 15px rgb(0 0 0 / 15%);
    border-radius: 10px;
}
.modal-body .payu-form .membership-item.selected{
	border:1px solid var(--color-semi-gold);
}

.modal-body .payu-form .membership-content{
	display: flex;
	flex:1;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.modal-body .payu-form .membership-items-header{
	text-transform: uppercase;
	text-align: center;
	color:var(--color-gold);
	font-size: 2.5rem;
	line-height: 2.5rem;
    font-weight: 900;
    margin: 2rem 0 2.5rem 0;
}

.modal-body .payu-form .item{
	font-size:20px;
	font-weight:bold;
}
.modal-body .payu-form .membership-item.selected .item{
	color:var(--color-gold);
}
.modal-body .payu-form .price{
	font-weight:bold;
	color:var(--color-green);
	font-size:20px;
	text-align: center;
	height:50px;
	justify-content: center;
	align-items: center;
	display: flex;
	border-top:1px solid #ddd;
	margin-top:15px;
	flex-direction: column;
	line-height: 20px;
}

.modal-body .payu-form .previous-price{
	font-size: 14px;
    text-decoration: line-through;
	font-weight:normal;
	color:var(--color-red);
}
.modal-body .payu-form .desc{
	font-size:14px;
}
.modal-body .payu-form .price span{
	font-weight:normal;
	color:#000000;
	font-size:14px;
}
.modal-body .payu-form .order p{
	font-size:1.4rem;
	margin:1rem 0 0 0;
}

.modal-body .payu-desc p{
	font-size:1.4rem;
}
.modal-body .payu-desc button{
	border-radius:0 0 10px 10px !important;
}
.modal-body .payu-desc .link-back{
	margin:1rem;
}
.modal-body h3{
	font-size: 1.4rem;
	font-weight:700;
	margin-bottom: 15px;
}
.modal-body .table td{
	font-size:1.4rem;
	border-bottom:1px solid #ddd;
}
.modal-body .group-memvers-list{
	display:flex;
	padding: 10px;
    border-bottom: 1px solid #ddd;
	align-items: center;
}
.modal-body .group-memvers-list:last-child{
    border-bottom: 0px none;
}
.modal-body .group-memvers-list:not(.no-hover){
	cursor:pointer;
}
.modal-body .group-memvers-list:hover:not(.no-hover){
	background-color:#fffaeb;	
}
.modal-body .group-memvers-list:hover:not(.no-hover) .info{
	color:var(--color-gold);	
}
.modal-body .group-memvers-list .img{
	width:30px;
	height:30px;
	margin-right:15px;
}
.modal-body .group-memvers-list .img img{
	width:100%;
	height:100%;
	object-fit: cover;
	border-radius:50%;
	border:1px solid #ddd;
}
.modal-body .group-memvers-list .info{
	font-size:1.4rem;
}
.modal-body .group-memvers-list .remove{
	height:17px;
	cursor:pointer;
}

.modal-body .group-memvers-list .accept-invitation{
	flex:1;
	text-align: right;
	padding-right:20px;
	color:var(--color-red);
	font-size: 1.2rem;
}

.modal-body .video-desc{
	font-size: 1.4rem;
	font-style: italic;
	text-align: center;
}
.modal-body .nav-tabs.with-border,
#vs-list .nav-tabs.with-border{
	padding:0 0 1rem 0;
	border-bottom:4px solid #ddd;
}
.visit-time{
	align-items: center;
	justify-content: center;
	display: flex;
	padding: 0 15px 0 0;
	color: var(--color-light);
	font-size: 1.2rem;
}
.c-notifications .c-notification {
    padding-bottom: 2rem;
    border-bottom: 1px solid var(--color-very-light-gray);
    margin-bottom: 2rem;
}
.c-notifications .c-notification:last-child {
    padding-bottom: 0rem;
    border-bottom: 0px none;
    margin-bottom: 0rem;
}
.payu-card-expm,
.payu-card-expy{
	width: 50px !important;
	text-align: center;
}
.payu-card-cvv{
	width: 75px !important;
	text-align: center;
}
.table{
	color:var(--color-dark) !important;
}
.table th{
	font-size:1.4rem;
	font-weight:600;
	padding: .5rem;
}
.table thead th {
    border-bottom: 4px solid #dee2e6 !important;
}
.table td{
	font-size:1.4rem;
	padding: .8rem;
}
.table td .badge{
	font-weight: 700;
	font-size: 1.2rem;
	line-height: 1.4rem;
	padding:0.8rem 2rem;
	min-width:100px;
	border-radius: 10px;
}
.table tr.total td{
	border-top:4px solid #ddd;
	background-color:var(--color-very-light-gray);
}
.table tr img{
	height:24px;
}
.table tr img.smaller{
	height:20px;
}
.switch-input-container{
	display:flex;
	align-items: center;
}
.switch-input-container label{
	cursor:pointer;
}
.switch-input-container .switch-input{
	height:4.6rem;
	width:8.6rem;
	background-color: var(--color-input);
	border-radius: 2.3rem;
	position:relative;
	transition: background-color 0.5s;
	cursor:pointer;
}
.switch-input-container .switch-input.smaller{
	height:3.6rem;
	width:6.6rem;
	border-radius: 1.3rem;
}
.switch-input-container .switch-input.smallest{
	height:2.6rem;
	width:4.6rem;
	border-radius: 1.3rem;
}
.switch-input-container .switch-input .thumb{
	border-radius: 2rem;
	width:4rem;
	height:4rem;
	position:absolute;
	left:0.3rem;
	top:0.3rem;
	transform: translateX(0);
	transition: transform 0.5s, background-color 0.5s;
	background-color:#fff;
}
.switch-input-container .switch-input.smaller .thumb{
	border-radius: 1rem;
	width:3rem;
	height:3rem;
}
.switch-input-container .switch-input.smallest .thumb{
	border-radius: 1rem;
	width:2rem;
	height:2rem;
}
.switch-input-container .switch-input.on{
	background-color: var(--color-green);
}
.switch-input-container .switch-input.on .thumb{
	transform: translateX(100%);
}
.room-icon{
	height:150px;
}
.alert{
	font-size:1.4rem;
	border-radius:1rem;
	font-weight: 600;
	margin:1rem 0 0 0;
}
.alert .count-down-time{
	font-size:3rem;
	font-weight:bold;
}
.online-status{
	width:6px;
	height:6px;
	border-radius:3px;
}

.online-status.online{
	background-color: var(--color-green);
}
.online-status.offline{
	background-color: var(--color-red);
}

.badge-danger{
	background: linear-gradient(180deg, #EA7782 0%, var(--color-red) 100%) !important;
}
.badge-success{
	background: linear-gradient(180deg, var(--color-green-light) 0%, var(--color-green) 100%) !important;
}
.badge-warning{
	background: linear-gradient(180deg, #fff3cd 0%, #ffeeba 100%) !important;
}
.badge{
	position:relative;
	white-space: initial !important;
}
.badge img{
	height:15px !important;
	margin-left:2px;
}
.badge-info-text-overlay{
	position: fixed;
	top:0;
	left:0;
	right:0;
	bottom:0;
	z-index: 1000;
}
.badge-info-text{
	position:absolute;
	top:25px;
	right:0px;
	background-color: rgba(0, 0, 0, 0.85);
	color:#fff;
	padding:1rem;
	border-radius: 1rem;
	width:200px;
	z-index: 10;
	line-height: 1.4rem;
    font-weight: 300;
}
.alert-danger{
	background: linear-gradient(180deg, #EA7782 0%, var(--color-red) 100%) !important;
	color:#fff;
}
.alert-success{
	background: linear-gradient(180deg, var(--color-green-light) 0%, var(--color-green) 100%) !important;
	color:#fff;
}
.alert-warning{
	background: linear-gradient(180deg, var(--color-light-gold) 0%, var(--color-light-gold) 100%) !important;
	color:var(--color-dark-gold);
}
.text-red{
	color:var(--color-red);
}
.italic{
	font-style: italic;
}
.btn{
	font-size:1.4rem !important;
	line-height: 1.4rem !important;
	text-transform: uppercase !important;
	padding:1.6rem !important;
	font-weight:700 !important;
	border-radius: 1rem !important;
	border:0px none;
}
.btn:active{
	color:var(--color-dark) !important;
}
.btn.focus, .btn:focus{
	box-shadow: none !important;
	color:var(--color-dark) !important;
}
.btn-thiner{
	padding:1rem 1.2rem !important;
}
.btn-thinest{
	padding:0.5rem 1.2rem !important;
	font-size:1.2rem !important;
}
.btn-primary,
.btn-primary:hover {
    background: linear-gradient(180deg, #FFEEB4 14.06%, #E7C054 100%);
	color:var(--color-dark);
}

.btn-secondary,
.btn-secondary:hover {
    background: linear-gradient(180deg, #c2c2c2 14.06%, #878787  100%);
	color:#ffffff;
}
.btn-info,
.btn-info:hover {
    background: linear-gradient(180deg, #b4f0ff 14.06%, #54cae7 100%);
	color:var(--color-dark);
}
.btn-danger,
.btn-danger:hover{
	background: linear-gradient(180deg, #EA7782 0%, var(--color-red) 100%) !important;
	color:#fff;
}
.btn-danger:active,
.btn-danger:focus{
	color:#fff !important;
}
.paid-off .btn{
	padding:2rem 4rem !important;
}
.paid-off .btn-secondary{
	cursor:default !important;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle,
.btn-secondary.focus, .btn-secondary:focus{
	color:#fff !important;
}

.btn-orange {
    background-color: var(--color-gold);
	border-color: var(--color-gold);
	color:#fff;
}
.btn-orange:hover {
    background-color: var(--color-gold);
	border-color: var(--color-gold);
	color:#fff;
}
.btn-delete-files{
	line-height: 10px;
    padding: 10px;
	height: 35px;
	border: 1px solid #ced4da;
}
.btn-delete-files:hover{
	border: 1px solid #ced4da;
}
.btn-large{
	padding:18px !important;
	font-size:1.6rem !important;
}
.btn-grey {
    background-color: #ddd !important;
    border-color: #ddd !important;
	cursor:default !important;
}
.btn-grey:hover {
    background-color: #ddd !important;
    border-color: #ddd !important;
	cursor:default !important;
}
.btn-transparent{
	border:0px none;
	background-color: transparent;
}
.btn .info{
	font-size:12px;
	text-transform: initial;
}
.btn-inf{
	border:1px solid #ddd;
	background-color: transparent;
	color:#000;
}
.btn-inf:hover{
	border:1px solid var(--color-gold);
	background-color: transparent;
	color:#000;
	cursor:pointer;
}
.no-bold{
	font-weight:normal !important;
}
.no-border-bottom{
	border-bottom:0px none !important;
}
.no-border{
	border:0px none !important;
}
.nav-tabs{
	margin-bottom:20px;
}
.nav-link {
    padding: 1.6rem 1.5rem;
}
.nav-tabs .nav-link{
	color:var(--color-dark);
	background-color:#fff;
	margin-right:5px;
	font-size:1.4rem;
	border-color: transparent;
	font-weight:600;
	border:0px none;
	border-radius:1rem;
}
.nav-tabs .nav-link.disabled{
	color:#cccccc;
	cursor:not-allowed;
	pointer-events:initial;
}
.StripeElement{
	padding: 20px;
    background: var(--color-input);
    border-radius: 1rem 1rem 0 0;
}
.delete-checked{
	vertical-align: middle;
	margin-left: 4px;
}
.info-desc{
	font-size:12px;
	font-style: italic;
}
.image-mirror{
	-webkit-transform: scaleX(-1);
  	transform: scaleX(-1);
}
.whole-loading{
	display:flex;
	height:100%;
	align-items:center;
	justify-content:center;
	padding: 50px 0px;
}
.whole-loading .white{
	color:#fff;
}
.scrollarea {
    height:100%;
}
.scrollarea .scrollbar-container.vertical {
    width: 4px !important;
    right: 2px !important;
}
.scrollarea .scrollbar-container.vertical .scrollbar {
    width: 4px !important;
	background: var(--color-gold) !important;
}
.scrollarea .scrollbar-container.active, 
.scrollarea .scrollbar-container:hover {
    background: #fff!important;
}
.scrollarea .scrollbar-container {
    opacity: .3!important;
}
.pre-wrap{
	white-space: pre-wrap;
}
.loading{
	text-align:center;
	font-size:1.4rem;
}
.no-flex{
	flex:none !important;
}
.no-margin{
	margin:0px !important;
}
.no-margin-right{
	margin-right:0px !important;
}
.no-border-radius{
	border-radius: 0px !important;
}
.ml-auto{
	margin-left:auto !important;
}
.w-40{
	width:40px;
}
.w-80{
	width:80px;
}
.lh-12{
	line-height:1.2rem;
}
.fs-12{
	font-size:1.2rem;
}
.fs-14{
	font-size:1.4rem;
}
.pl-15{
	padding-left:15px !important;
}
.p-15{
	padding:15px !important;
}
.p-12{
	padding:12px !important;
}
.bold{
	font-weight:bold;
}
.hide{
	display:none;
}
.badge.not-specified{
	color:#fff;
}
.vh-100{
	height:calc(100vh - 45px) !important;
}
.maxwidth-100{
	max-width:100%;
}
.modal-lg {
    max-width: 800px !important;
}
.modal-xl {
    max-width: 1199px !important;
}
.custom-file-input{
	cursor:pointer;
}
.custom-file-label::after {
	content: "Vybrat" !important;
	background-color:#00aeef;
	color:#fff;
}
.border-bottom-radius{
	border-bottom-left-radius:0.3rem;
	border-bottom-right-radius:0.3rem;
}
.cursor-pointer{
	cursor:pointer;
}

.no-padding-right{
	padding-right:0px !important;
}
.no-padding{
	padding:0px !important;
}

.flex-1{
	flex:1;
}
.custom-file{
	height:auto;
}
.custom-file-input{
	height:auto;
	padding:1rem;
}
.custom-file-input:focus,
.custom-file-label {
    border-color: transparent;
    box-shadow: none;
	background-color: var(--color-input);
	border-radius:1rem;
	height:auto;
	padding:1rem;
}
.custom-file-label::after {
    background: linear-gradient(180deg, #FFEEB4 14.06%, #E7C054 100%);
	background-color: transparent;
    color: var(--color-dark);
	font-weight:600;
	border-radius:0 1rem 1rem 0;
	height:auto;
	display: flex;
	align-items: center;
}
.custom-file-input:focus~.custom-file-label{
	box-shadow: none;
	border-color:transparent;
}

.ReactModal__Overlay {
  opacity: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.85) !important;
  z-index:1300 !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transition: opacity 150ms ease-out;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content.modal-dialog {
  border: none;
  background-color: transparent;
}

.ReactModal__Content .event iframe{
    margin:0px;
    width:100%;
	border:0px none;
	height: calc(1119px * 9 / 16);
}
.ReactModal__Content.transparent .modal-content{
	background-color:transparent;
	border:0px none;
}
.ReactModal__Content.transparent .modal-body{
	background-color:transparent;
}
.ReactModal__Content.transparent .modal-header h4{
	color:#fff;
}
.ReactModal__Content.transparent .close{
	color:#fff;
	opacity:1;
}
.ReactModal__Content .close{
	opacity:1 !important;
	cursor:pointer;
	margin: 2px 0 0 auto !important;
	padding:0 !important;
	height:24px;
}

.ReactCrop__image{
	max-height:100% !important;
}
.justify-flex-start{
	justify-content: flex-start !important;
}
.spinner {
  margin: 10px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background: linear-gradient(180deg, #FFEEB4 14.06%, #E7C054 100%);
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-right:2px;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.table th{
	border-top:0px none;
}
.table td, .table th{
	vertical-align: middle;
	border-bottom: 1px solid #dee2e6;
}
.no-top-margin{
	margin-top:0px !important;
}
.no-top-padding{
	padding-top:0px !important;
}

.no-bottom-padding{
	padding-bottom:0px !important;
}
.no-bottom-margin{
	margin-bottom:0px !important;
}
.f-12{
	font-size:1.2rem !important;
}
.f-13{
	font-size:1.3rem !important;
}
.f-14{
	font-size:1.4rem !important;
}
.flex1{
	flex:1;
}
.attention-icon{
	height:150px;
}
.no-dots{
	list-style-type: none;
	padding:0px;
	margin:0px;
}

.nav-tabs .nav-link {
    border: 1px solid #fff;
}
.nav-tabs .nav-link.active {
    border: 1px solid var(--color-semi-gold);
    background: radial-gradient(50% 100% at 50% 100%, #FFF9E2 0%, rgba(255, 255, 255, 0) 100%);
    background-color: #fff;
}
.nav.nav-tabs{
	padding-left:0px !important;
	position:relative;
	z-index: 1;
}
.position-relative{
	position:relative;
}
.disable-select {
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}
.emoji-mart-category .emoji-mart-emoji span{
	cursor:pointer !important;
}
.reservation-calendar{
    display: inline-block;
    border-radius: 1rem;
    border:1px solid #ddd;
    background-color: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.reservation-calendar .months{
    display:flex;
    border-bottom:1px solid #ddd;
    padding:1rem;
}
.reservation-calendar .months .month{
    font-size:1.4rem;
    flex:1;
    text-align: center;
    text-transform: capitalize;
}
.reservation-calendar .months .arrow{
    width:1.7rem;
}
.reservation-calendar .months .arrow img{
    width:100%;
    cursor:pointer;
}
.reservation-calendar .months img.right{
    transform: rotateZ(180deg);
}
.reservation-calendar .days{
    display:flex;
    padding:1rem;
    flex-direction: column;
}
.reservation-calendar .days-row{
    display:flex;
    align-items: center;
}
.reservation-calendar .day{
    display: flex;
    justify-content: center;
    align-items: center;
    min-width:3rem;
    min-height:3rem;
    font-size:1.4rem;
    padding:0.5rem;
}
.reservation-calendar .days .days-row:not(.names) .day:not(.disabled):not(.in-past):not(.blocked):not(.empty){
    cursor:pointer;
}
.reservation-calendar .days .days-row:not(.names) .day.clickable{
    cursor:pointer;
}

.reservation-calendar .days .days-row:not(.names) .day:not(.blocked):not(.disabled):not(.in-past):not(.selected):not(.empty):hover{
    background-color: #eee;
}
.reservation-calendar .days .days-row:not(.names) .day.selected:not(.blocked):hover{
    background-color: #ddd;
}
.reservation-calendar .days .day{
    border:1px solid transparent;
    border-radius:1rem;
}
.reservation-calendar .days .day.in-past,
.reservation-calendar .legend .day.in-past{
    color:#b5b5b5;
}
.reservation-calendar .days .day.selected,
.reservation-calendar .legend .day.selected{
    background-color: var(--color-gold);
    color:#fff;
}
.reservation-calendar .days .day.blocked,
.reservation-calendar .legend .day.blocked{
    background-color: var(--color-red);
    color:#fff;
}

.reservation-calendar .legend{
    border-top:1px solid #ddd;
    padding:1rem;
}
.reservation-calendar .legend .day{
    min-width:1.7rem;
    min-height:1.7rem;
    border-radius:0.5rem;
}

.mb-2rem{
	margin-bottom:2rem;
}
.mt-2rem{
	margin-top:2rem;
}
.mb-space{
	margin-bottom:4rem;
}
.min-w-250{
	min-width:250px;
}
.ml-30{
	margin-left:1.875rem;
}
.mr-30{
	margin-right:1.875rem;
}
.mt-30{
	margin-top:1.875rem;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}



@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4);}  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}














.pyro > .before,
.pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  z-index:10;
  box-shadow: 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff, 0 0 #fff;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro > .after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 1.25s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  to {
    box-shadow: -214px -264.6666666667px #00d9ff, 2px -0.6666666667px #ff6600, -177px -26.6666666667px #ff8800, -74px 23.3333333333px #ff004d, -29px -214.6666666667px #ff0026, 213px -226.6666666667px #00ff44, -160px -401.6666666667px #ffae00, -80px -68.6666666667px #ff00b3, 39px -47.6666666667px #ff0080, -72px -48.6666666667px #eaff00, 44px -273.6666666667px #e1ff00, -47px -92.6666666667px #ff00f7, 245px -53.6666666667px #ff1500, 70px -168.6666666667px #0011ff, 49px -236.6666666667px #0033ff, -58px -14.6666666667px yellow, -27px -33.6666666667px #ff4d00, 169px -70.6666666667px #007bff, 212px -211.6666666667px #ff2600, 44px 7.3333333333px #00ffaa, 53px -236.6666666667px #ff2f00, -216px -244.6666666667px #1500ff, 231px 51.3333333333px #ddff00, -216px -387.6666666667px #ff0091, -139px -126.6666666667px #0059ff, -32px -274.6666666667px #fbff00, -146px 72.3333333333px #ff00bb, 130px -387.6666666667px #00ff2b, 73px -256.6666666667px #ff1e00, 18px -392.6666666667px #fff200, -144px -149.6666666667px deepskyblue, -155px -42.6666666667px #ff0044, -229px -407.6666666667px #ffa200, 38px -331.6666666667px #fb00ff, 203px -261.6666666667px #99ff00, 15px -242.6666666667px #ff000d, 149px -357.6666666667px #fbff00, -178px -294.6666666667px #ff002f, -230px -47.6666666667px #ff0011, 241px -48.6666666667px #09ff00, -135px -112.6666666667px #6f00ff, 84px -324.6666666667px #00ff62, -12px -309.6666666667px #ff008c, 223px -141.6666666667px #ff00fb, 98px -18.6666666667px #00fbff, 121px -327.6666666667px #1100ff, -241px -118.6666666667px #ff00a2, 13px -71.6666666667px #0015ff, -64px -126.6666666667px #5e00ff, -75px -352.6666666667px #00ff66, 195px -352.6666666667px #ffe600;
  }
}
@-moz-keyframes bang {
  to {
    box-shadow: -214px -264.6666666667px #00d9ff, 2px -0.6666666667px #ff6600, -177px -26.6666666667px #ff8800, -74px 23.3333333333px #ff004d, -29px -214.6666666667px #ff0026, 213px -226.6666666667px #00ff44, -160px -401.6666666667px #ffae00, -80px -68.6666666667px #ff00b3, 39px -47.6666666667px #ff0080, -72px -48.6666666667px #eaff00, 44px -273.6666666667px #e1ff00, -47px -92.6666666667px #ff00f7, 245px -53.6666666667px #ff1500, 70px -168.6666666667px #0011ff, 49px -236.6666666667px #0033ff, -58px -14.6666666667px yellow, -27px -33.6666666667px #ff4d00, 169px -70.6666666667px #007bff, 212px -211.6666666667px #ff2600, 44px 7.3333333333px #00ffaa, 53px -236.6666666667px #ff2f00, -216px -244.6666666667px #1500ff, 231px 51.3333333333px #ddff00, -216px -387.6666666667px #ff0091, -139px -126.6666666667px #0059ff, -32px -274.6666666667px #fbff00, -146px 72.3333333333px #ff00bb, 130px -387.6666666667px #00ff2b, 73px -256.6666666667px #ff1e00, 18px -392.6666666667px #fff200, -144px -149.6666666667px deepskyblue, -155px -42.6666666667px #ff0044, -229px -407.6666666667px #ffa200, 38px -331.6666666667px #fb00ff, 203px -261.6666666667px #99ff00, 15px -242.6666666667px #ff000d, 149px -357.6666666667px #fbff00, -178px -294.6666666667px #ff002f, -230px -47.6666666667px #ff0011, 241px -48.6666666667px #09ff00, -135px -112.6666666667px #6f00ff, 84px -324.6666666667px #00ff62, -12px -309.6666666667px #ff008c, 223px -141.6666666667px #ff00fb, 98px -18.6666666667px #00fbff, 121px -327.6666666667px #1100ff, -241px -118.6666666667px #ff00a2, 13px -71.6666666667px #0015ff, -64px -126.6666666667px #5e00ff, -75px -352.6666666667px #00ff66, 195px -352.6666666667px #ffe600;
  }
}
@-o-keyframes bang {
  to {
    box-shadow: -214px -264.6666666667px #00d9ff, 2px -0.6666666667px #ff6600, -177px -26.6666666667px #ff8800, -74px 23.3333333333px #ff004d, -29px -214.6666666667px #ff0026, 213px -226.6666666667px #00ff44, -160px -401.6666666667px #ffae00, -80px -68.6666666667px #ff00b3, 39px -47.6666666667px #ff0080, -72px -48.6666666667px #eaff00, 44px -273.6666666667px #e1ff00, -47px -92.6666666667px #ff00f7, 245px -53.6666666667px #ff1500, 70px -168.6666666667px #0011ff, 49px -236.6666666667px #0033ff, -58px -14.6666666667px yellow, -27px -33.6666666667px #ff4d00, 169px -70.6666666667px #007bff, 212px -211.6666666667px #ff2600, 44px 7.3333333333px #00ffaa, 53px -236.6666666667px #ff2f00, -216px -244.6666666667px #1500ff, 231px 51.3333333333px #ddff00, -216px -387.6666666667px #ff0091, -139px -126.6666666667px #0059ff, -32px -274.6666666667px #fbff00, -146px 72.3333333333px #ff00bb, 130px -387.6666666667px #00ff2b, 73px -256.6666666667px #ff1e00, 18px -392.6666666667px #fff200, -144px -149.6666666667px deepskyblue, -155px -42.6666666667px #ff0044, -229px -407.6666666667px #ffa200, 38px -331.6666666667px #fb00ff, 203px -261.6666666667px #99ff00, 15px -242.6666666667px #ff000d, 149px -357.6666666667px #fbff00, -178px -294.6666666667px #ff002f, -230px -47.6666666667px #ff0011, 241px -48.6666666667px #09ff00, -135px -112.6666666667px #6f00ff, 84px -324.6666666667px #00ff62, -12px -309.6666666667px #ff008c, 223px -141.6666666667px #ff00fb, 98px -18.6666666667px #00fbff, 121px -327.6666666667px #1100ff, -241px -118.6666666667px #ff00a2, 13px -71.6666666667px #0015ff, -64px -126.6666666667px #5e00ff, -75px -352.6666666667px #00ff66, 195px -352.6666666667px #ffe600;
  }
}
@-ms-keyframes bang {
  to {
    box-shadow: -214px -264.6666666667px #00d9ff, 2px -0.6666666667px #ff6600, -177px -26.6666666667px #ff8800, -74px 23.3333333333px #ff004d, -29px -214.6666666667px #ff0026, 213px -226.6666666667px #00ff44, -160px -401.6666666667px #ffae00, -80px -68.6666666667px #ff00b3, 39px -47.6666666667px #ff0080, -72px -48.6666666667px #eaff00, 44px -273.6666666667px #e1ff00, -47px -92.6666666667px #ff00f7, 245px -53.6666666667px #ff1500, 70px -168.6666666667px #0011ff, 49px -236.6666666667px #0033ff, -58px -14.6666666667px yellow, -27px -33.6666666667px #ff4d00, 169px -70.6666666667px #007bff, 212px -211.6666666667px #ff2600, 44px 7.3333333333px #00ffaa, 53px -236.6666666667px #ff2f00, -216px -244.6666666667px #1500ff, 231px 51.3333333333px #ddff00, -216px -387.6666666667px #ff0091, -139px -126.6666666667px #0059ff, -32px -274.6666666667px #fbff00, -146px 72.3333333333px #ff00bb, 130px -387.6666666667px #00ff2b, 73px -256.6666666667px #ff1e00, 18px -392.6666666667px #fff200, -144px -149.6666666667px deepskyblue, -155px -42.6666666667px #ff0044, -229px -407.6666666667px #ffa200, 38px -331.6666666667px #fb00ff, 203px -261.6666666667px #99ff00, 15px -242.6666666667px #ff000d, 149px -357.6666666667px #fbff00, -178px -294.6666666667px #ff002f, -230px -47.6666666667px #ff0011, 241px -48.6666666667px #09ff00, -135px -112.6666666667px #6f00ff, 84px -324.6666666667px #00ff62, -12px -309.6666666667px #ff008c, 223px -141.6666666667px #ff00fb, 98px -18.6666666667px #00fbff, 121px -327.6666666667px #1100ff, -241px -118.6666666667px #ff00a2, 13px -71.6666666667px #0015ff, -64px -126.6666666667px #5e00ff, -75px -352.6666666667px #00ff66, 195px -352.6666666667px #ffe600;
  }
}
@keyframes bang {
  to {
    box-shadow: -214px -264.6666666667px #00d9ff, 2px -0.6666666667px #ff6600, -177px -26.6666666667px #ff8800, -74px 23.3333333333px #ff004d, -29px -214.6666666667px #ff0026, 213px -226.6666666667px #00ff44, -160px -401.6666666667px #ffae00, -80px -68.6666666667px #ff00b3, 39px -47.6666666667px #ff0080, -72px -48.6666666667px #eaff00, 44px -273.6666666667px #e1ff00, -47px -92.6666666667px #ff00f7, 245px -53.6666666667px #ff1500, 70px -168.6666666667px #0011ff, 49px -236.6666666667px #0033ff, -58px -14.6666666667px yellow, -27px -33.6666666667px #ff4d00, 169px -70.6666666667px #007bff, 212px -211.6666666667px #ff2600, 44px 7.3333333333px #00ffaa, 53px -236.6666666667px #ff2f00, -216px -244.6666666667px #1500ff, 231px 51.3333333333px #ddff00, -216px -387.6666666667px #ff0091, -139px -126.6666666667px #0059ff, -32px -274.6666666667px #fbff00, -146px 72.3333333333px #ff00bb, 130px -387.6666666667px #00ff2b, 73px -256.6666666667px #ff1e00, 18px -392.6666666667px #fff200, -144px -149.6666666667px deepskyblue, -155px -42.6666666667px #ff0044, -229px -407.6666666667px #ffa200, 38px -331.6666666667px #fb00ff, 203px -261.6666666667px #99ff00, 15px -242.6666666667px #ff000d, 149px -357.6666666667px #fbff00, -178px -294.6666666667px #ff002f, -230px -47.6666666667px #ff0011, 241px -48.6666666667px #09ff00, -135px -112.6666666667px #6f00ff, 84px -324.6666666667px #00ff62, -12px -309.6666666667px #ff008c, 223px -141.6666666667px #ff00fb, 98px -18.6666666667px #00fbff, 121px -327.6666666667px #1100ff, -241px -118.6666666667px #ff00a2, 13px -71.6666666667px #0015ff, -64px -126.6666666667px #5e00ff, -75px -352.6666666667px #00ff66, 195px -352.6666666667px #ffe600;
  }
}
@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}
@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}
@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

